import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const ParentComponent = () => {
    const iframeRef = useRef(null);
    const [UserValue, setValue] = useState('');
    const [metaverseValue, setMetaverseValue] = useState('');
    const [msg,setMsg] = useState()
    const[ticket,setTicket] = useState()
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [inputValue, setInputValue] = useState();
    

    const [jsonInput, setJsonInput] = useState('');


    const handleInputChange = (event) => {
      setJsonInput(event.target.value);
    };

   

    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('user_id');
        const metaverseId = searchParams.get('metaverse_id');
        
        
        if(userId) setValue(userId)
        if(metaverseValue) setMetaverseValue(metaverseId)
          //https://api.builder.holofair.io/api/user_profile?user_id=1
        // const data = {
        //     user_id: userId,
        //     metaverse_id: metaverseId || "3",
        //     APIAddress: "https://api.builder.holofair.io/api/addressables/{metaverse_id}/{platform}",
        //     Host: "",
        //     Port: "",
        //     MaxClients: "256",
        //    // UserProfileAPIGETAddress: "https://majidalfuttaim.enterprise.holofair.io/api/user_profile?user_id=",
        //     UserProfileAPIGETAddress: "https://api.builder.holofair.io/api/user_profile?user_id=",
        //     AnalyticsStartedAPI: "https://api.builder.holofair.io/api/interactions/add/started?metaverse_id={metaverse_id}",
        //     AnalyticsEndedAPI: "https://api.builder.holofair.io/api/interactions/update/ended?metaverse_id={metaverse_id}",
        //     AnalyticsImmediateAPI: "https://api.builder.holofair.io/api/interactions/add/immediate?metaverse_id={metaverse_id}",
        //     useHathora: "True",
        //     key1: "value1",
        //     key2: "value2",
          
        // };


        const data = {
          user_id: userId || 1,
          metaverse_id: metaverseId || "3",
      };

      const text = "I am going to send data";

        const message = { type: 'holofair', data: data };
        const beforeLoad = { type: 'beforeLoad', data: text };

        const sendMessage = () => {
            iframeRef.current.contentWindow.postMessage(message, '*');
        };

        const sendMessageBeforeLoad = () =>{
          iframeRef.current.contentWindow.postMessage(beforeLoad, '*');
        }

        // Send message after iframe is loaded
        // iframeRef.current.onload = () => {
        //     sendMessage();
        // };

        if (iframeRef.current.readyState === 'complete') {
            sendMessage();
            sendMessageBeforeLoad();
          } else {
            iframeRef.current.onload = () => {
              sendMessage();
              sendMessageBeforeLoad();
            };
          }


    }, [location.search, metaverseValue]);




    const handleHoloFairMessage = useCallback((holofairData) => {
      const { selectedService, selectedCenter } = holofairData;
      // Process the received data
      console.log('Service ID:', selectedService);
      console.log('Center ID:', selectedCenter);
     
      
      const response = {
      ticketid: 'ticket123'
      };
      setTicket(response)
      //console.log(ticket)
      // sendDataToHoloFair(response);
      },[])



    
  useEffect(() => {
    // Function to handle messages from the iframe
    const handleMessage = (event) => {
      // Check if the message is coming from your iframe's origin
      // if (event.origin !== 'http://localhost:3000') {
      //   return;
      // }

      console.log('Message received from HoloFair Enterprise:', event.data);
      setMsg(event.data.unityEventData)
      if(event.data.unityEventData){ 
      const parsedData = JSON.parse(event.data.unityEventData)
      console.log(parsedData.selectedCenter)
      handleHoloFairMessage(parsedData)
    }
    };

   
    window.addEventListener('message', handleMessage);

  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleHoloFairMessage]);


  const sendDataToHoloFair = useCallback( (event) => {
    event.preventDefault();

    const trimmedInput = jsonInput.trim();
    // console.log('Raw Input:', jsonInput);
    // console.log('Trimmed Input:', trimmedInput);

    if (trimmedInput) {
      try {
        const parsedJson = JSON.parse(trimmedInput);
        console.log('JSON:', parsedJson);
      } catch (error) {
        console.error('Invalid JSON:', error.message);
      }
    } else {
      console.error('Empty input. Please provide a valid JSON string.');
    }


   

    // console.log(formData)
     const message = { type: 'ticket', data: formData };
     console.log({message})
     if (iframeRef.current) {
       // const message = { unityEventData: JSON.stringify({ APILink: 'https://api.example.com' }) };
      // iframeRef.current.contentWindow.postMessage(message, '*');
   
     }
  },[formData, jsonInput]);
  


  useEffect(() =>{

   
    //if(ticket) {sendDataToHoloFair(ticket)}
   
    

 

  },[])




  const sendObject = (event) =>{
  //   event.preventDefault();

  //   const data = inputs.reduce((acc, input) => {
  //     if (input.key) {
  //         acc[input.key] = input.value;
  //     }
  //     return acc;
  // }, {});


  //   setFormData(data);
  //   console.log(data);
  }



useEffect(() => {
  console.log('Current jsonInput state:', jsonInput);
}, [jsonInput]);

const handleButtonClick = () => {
  const trimmedInput = jsonInput.trim();
  // console.log('Raw Input:', jsonInput);
  // console.log('Trimmed Input:', trimmedInput);

  if (trimmedInput) {
    try {
      const parsedJson = JSON.parse(trimmedInput);
      console.log('JSON:', parsedJson);
      setInputValue(jsonInput)
      const message = { type: 'ticket', data: parsedJson };
      console.log({message})
      if (iframeRef.current) {
        // const message = { unityEventData: JSON.stringify({ APILink: 'https://api.example.com' }) };
        iframeRef.current.contentWindow.postMessage(message, '*');
    
      }




    } catch (error) {
      console.error('Invalid JSON:', error.message);
    }
  } else {
    console.error('Empty input. Please provide a valid JSON string.');
  }
};




    return (
      // <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0 }}>
      <>
      <h1>DIJI Presence APP</h1>
  
      <br></br>
      
    
      <input
        type="text"
        value={jsonInput}
        onChange={handleInputChange}
        placeholder='Enter JSON string e.g. {"a":1,"b":2}'
      />
     
      
      <button onClick={handleButtonClick}>Send to HoloFair</button><br></br>
      {inputValue &&
      <span>Sent data "{inputValue}"</span>
      }
      <hr />
      <h2>Messages from Holofair</h2>
      {msg &&
      <p style={{"padding":"15px"}}>Message received from HoloFair App <br/> {msg} </p>
      }
      
      <iframe 
          ref={iframeRef} 
          src="https://dev.app.holofair.io"
         // src="http://localhost:3000"
          style={{ width: '100%', height: '100vh', border: 'none'}} 
          title="Holofair Iframe"
          allow="camera;microphone"
      ></iframe>
      </>
  // </div>
    );
};

export default ParentComponent;
